<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
      >
        <v-card class="content-card-padding">
          <v-expansion-panels
            v-model="panel"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>Contact Details</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <label
                  v-if="companyName"
                  class="description d-block"
                >
                  Define the contact details that should be shown in the
                  footer of your <b>{{ companyName }}</b> Kickbox Portal
                </label>
                <v-text-field
                  ref="line1"
                  v-model="line1"
                  :rules="[rules.required]"
                  label="Line 1*"
                  clearable
                  required
                  class="mt-4"
                  placeholder="Company Name (e.g. Swisscom Pirates Hub)"
                />
                <v-text-field
                  ref="line2"
                  v-model="line2"
                  label="Line 2"
                  clearable
                  class="mt-4"
                  placeholder="Street & Number (e.g. Konradstrasse 12)"
                />
                <v-text-field
                  ref="line3"
                  v-model="line3"
                  label="Line 3"
                  clearable
                  placeholder="Postal Code and City (e.g. CH-8005 Zurich)"
                  class="mt-4"
                />
                <v-text-field
                  ref="email"
                  v-model="email"
                  label="Email"
                  clearable
                  placeholder="Email address"
                  class="mt-4"
                />
                <v-text-field
                  ref="website"
                  v-model="website"
                  label="Website"
                  clearable
                  placeholder="Enter a website"
                  class="mt-4"
                />
                <v-text-field
                  ref="phoneNumber"
                  v-model="phoneNumber"
                  label="Phone Number"
                  clearable
                  placeholder="Enter a phone number"
                  class="mt-4"
                />
                <label class="body-1">* Mandatory field</label>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>Links</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <label
                  v-if="companyName"
                  class="description d-block"
                >
                  <p>Define the links displayed in the footer of the
                    <b>{{ companyName }}</b> Kickbox Portal.
                    Don’t forget to add both a Title and a URL.
                  </p>
                  You can also leave the links empty then the Default Terms and Conditions
                  and Imprint links will be show in the footer.
                  If you do not use the Default Terms and Conditions we suggest you adding
                  your custom Terms and Conditions link here.
                </label>
                <v-text-field
                  ref="urlTitle1"
                  v-model="urlTitle1"
                  label="Title of url 1"
                  clearable
                  class="mt-4"
                />
                <v-text-field
                  ref="url1"
                  v-model="url1"
                  label="Url 1"
                  placeholder="https://www.your-page.com"
                  clearable
                  class="mt-4"
                />
                <v-text-field
                  ref="urlTitle2"
                  v-model="urlTitle2"
                  label="Title of url 2"
                  clearable
                  class="mt-4"
                />
                <v-text-field
                  ref="url2"
                  v-model="url2"
                  label="Url 2"
                  placeholder="https://www.your-page.com"
                  clearable
                  class="mt-4"
                />
                <v-text-field
                  ref="urlTitle3"
                  v-model="urlTitle3"
                  label="Title of url 3"
                  clearable
                  class="mt-4"
                />
                <v-text-field
                  ref="url3"
                  v-model="url3"
                  label="Url 3"
                  placeholder="https://www.your-page.com"
                  clearable
                  class="mt-4"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="mt-4">
            <app-button
              block
              :loading="loadingButton"
              @click="saveFooter"
            >
              Save
            </app-button>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ValidateMixin } from '@kickbox/common-admin';
import companyService from '@/services/companyService';

export default {
  mixins: [ValidateMixin],
  data() {
    return {
      line1: '',
      line2: '',
      line3: '',
      email: '',
      website: '',
      phoneNumber: '',
      urlTitle1: '',
      url1: '',
      urlTitle2: '',
      url2: '',
      urlTitle3: '',
      url3: '',
      panel: [],
      companyName: '',
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    formFields() {
      return {
        line1: this.line1,
        line2: this.line2,
        line3: this.line3,
        url1: this.url1,
        urlTitle1: this.urlTitle1,
        url2: this.url2,
        urlTitle2: this.urlTitle2,
        url3: this.url3,
        urlTitle3: this.urlTitle3,
        email: this.email,
        website: this.website,
        phoneNumber: this.phoneNumber,
      };
    }
  },
  created() {
    if (this.company.footer) {
      this.line1 = this.company.footer.line1;
      this.line2 = this.company.footer.line2;
      this.line3 = this.company.footer.line3;
      this.url1 = this.company.footer.url1;
      this.urlTitle1 = this.company.footer.urlTitle1;
      this.url2 = this.company.footer.url2;
      this.urlTitle2 = this.company.footer.urlTitle2;
      this.url3 = this.company.footer.url3;
      this.urlTitle3 = this.company.footer.urlTitle3;
      this.email = this.company.footer.email;
      this.website = this.company.footer.website;
      this.phoneNumber = this.company.footer.phoneNumber;
      this.companyName = this.company.name;
    }
  },
  methods: {
    ...mapMutations([
      'updateUser',
      'updateCompany'
    ]),
    async saveFooter() {
      if (!this.line1) {
        return this.$store.dispatch('showSnackBar', { text: 'Please, fill in the field "Line 1" in "Contact Details"' });
      }
      const footerProperties = this.getValidatedEntityProperties(this.formFields);
      const footer = await companyService.setCompanyFooter(footerProperties.setProperties);
      this.updateCompany({
        ...this.company,
        footer
      });
      this.$store.dispatch('showSnackBar', { text: 'The footer values were saved.' });
      return true;
    }
  }
};
</script>

<style scoped>
  label {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
  }

  label.description {
    font-size: 14px;
  }
</style>
